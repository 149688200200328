import { useMemo } from 'react';
import { NextSeo, QAPageJsonLd } from 'next-seo';

import { DISCUSSION } from '~/lib/discussions/constants';
import { QuestionV2 } from '~/lib/discussions/types';
import { Deal } from '~/types/deal';
import { getQAndRPageCanonicalUrl } from '~/lib/discussions/utils';
import { firstSentence, lastWordAt } from '~/lib/util';
import { getQuestionPageLDSchema } from '~/lib/util/schemaLD';

export function QuestionSeo({
  question,
  deal,
}: {
  question: QuestionV2;
  deal: Deal;
}) {
  const dealName = useMemo(() => deal.public_name, [deal.public_name]);

  // Add default value for questions without a title to prevent raising errors.
  const questionTitle = question?.title || question?.comment || 'Question';
  const title = questionTitle
    ? `${firstSentence({ text: questionTitle, maxLength: 60 })} | ${dealName}`
    : `${dealName}`;
  const description = lastWordAt(question.comment || question.title || '');
  const questionJSONLD = getQuestionPageLDSchema(question, deal);

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={getQAndRPageCanonicalUrl(deal.slug, DISCUSSION.QUESTIONS)}
      />
      <QAPageJsonLd {...questionJSONLD} />
    </>
  );
}
